import React, { Component } from "react";
import ChatBot from "@optimlx/villachat";


class VillaChatAgent extends Component {

 
  render() {
    return (
      <><><div align="center">
    </div><ChatBot message={{client_id:"100001"}}/></></>
    
    );
  }
}
export default VillaChatAgent;